import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { SelectChangeEvent } from "@mui/material";

interface ArrayData {
  id: string,
  attributes: {
    name: string
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;

  wantTo: string;
  fullName: string;
  email: string;
  password: string;
  showPassword: boolean;
  isEmailValid: boolean;
  checkTerms: boolean;
  wantToRoleId: string;
  openMenuDropdown: boolean;

  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  isValidLength: boolean;
  wantToData: ArrayData[];
  showCheckboxError: boolean;
  showEmailExistError: boolean;
  showPasswordExistError: boolean;
  showNameExistError: boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  getAllWantToAPICallId: string = "";
  getSignUpAPICallId: string = "";
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",

      wantTo: "",
      fullName: "",
      email: "",
      password: "",
      showPassword: false,
      isEmailValid: true,
      checkTerms: false,
      wantToRoleId: "",
      openMenuDropdown: false,

      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      isValidLength: false,
      wantToData: [],
      showCheckboxError: false,
      showEmailExistError: false,
      showPasswordExistError: false,
      showNameExistError: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    this.recevieData(from, message);
    this.recevieDataError(from, message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  recevieData(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if(apiRequestCallId === this.getAllWantToAPICallId) { 
          const response = responseJson?.data;
          this.setState({
            wantToData: response
          })
        } else if(apiRequestCallId === this.getSignUpAPICallId && !responseJson?.errors) {
          this.handleNavigation(responseJson);
        }
    }
  }

  handleNavigation(responseJson: any) {
    if(responseJson) {
      let signUpData = {
        "id":responseJson.data.id,
        "email":responseJson.data.attributes.email,
        "role": responseJson.data.attributes.role,
        "fullName":responseJson.data.attributes.full_name,
        "activated":responseJson.data.attributes.activated,
        "token":responseJson.meta.token,
      }
      localStorage.setItem("login", JSON.stringify(signUpData));
    }
    if(this.state.wantTo === "Hire A Teen") {
      this.props.navigation.navigate("AdvancedSearch");
    } else {
      this.props.navigation.navigate("UserProfileBasicBlock");
    }
  }

  recevieDataError(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if(apiRequestCallId === this.getSignUpAPICallId && responseJson?.errors) {
          if(!responseJson.errors[0]?.password) {
            this.setState({
              showEmailExistError: true
            })
          } else {
            this.setState({
              showPasswordExistError: true
            })
          }
        }
    }
  }

  async componentDidMount() {
    this.wantToAPI();
  }

  wantToAPI() {
    const PCheaders = {
      "Content-Type": configJSON.getApiContentType,
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllWantToAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchWantToApiEndPoint)

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  handleSignUp() {

    if(!this.state.fullName) {
      this.setState({
        showNameExistError: true
      });
      return;
    }
    if(!this.state.checkTerms) {
      this.setState({
        showCheckboxError: true
      });
      return;
    }
    if(!this.state.password || !this.state.isValidLength || !this.state.hasNumber ||
      !this.state.hasLowerCase || !this.state.hasUpperCase
     ) {
      this.setState({
        showPasswordExistError: true
      });
      return;
    }

    const PCheaders = {
      "Content-Type": configJSON.getApiContentType,
    };

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password,
          full_name: this.state.fullName,
          role_id: this.state.wantToRoleId
        }
      }
    }

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSignUpAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpApiEndPoint)

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      PCheaders);

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod);

    getAllData.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody));

    runEngine.sendMessage(getAllData.id, getAllData);
  }

  handleWantTo(event: SelectChangeEvent) {
    this.setState({
      wantTo: event.target.value
    });

    const roleIdFilter = this.state.wantToData.filter((item: ArrayData) => {
      return item.attributes.name == event.target.value;
    });

    this.setState({
      wantToRoleId: roleIdFilter[0]?.id
    })

  }

  navigateToPolicy() {
    localStorage.setItem("termsAndPolicy", "1");
    this.props.navigation.navigate("TermsConditions")
  }

  navigateToTermsAndConditions() {
    localStorage.setItem("termsAndPolicy", "2");
    this.props.navigation.navigate("TermsConditions")
  }

  handlePassword(event: React.ChangeEvent<HTMLInputElement>) {
    const password = event.target.value;
    const minLength = 8;
    const hasUpperCaseData = /[A-Z]/.test(password);
    const hasLowerCaseData = /[a-z]/.test(password);
    const hasNumberData = /\d/.test(password);
    const isValidLengthData = password.length >= minLength;

    this.setState({
      hasLowerCase: hasLowerCaseData,
      hasUpperCase: hasUpperCaseData,
      hasNumber: hasNumberData,
      isValidLength: isValidLengthData,
      password: password
    });
  }

  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  handleFullName(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      showNameExistError: event.target.value ? false: true,
      fullName: event.target.value
    });
  }

  handleTermsCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      checkTerms: event.target.checked
    });
  }

  handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
    const isEmailValidCheck = this.checkEmailIsValid(event.target.value);
    this.setState({
      isEmailValid: isEmailValidCheck,
      email: event.target.value ? event.target.value : "null"
    })
  }

  checkEmailIsValid(email: string) {
    let atSymbol = email.indexOf("@");
    let dotSymbol = email.lastIndexOf(".");
    let spaceSymbol = email.indexOf(" ");

    return (atSymbol != -1 && atSymbol != 0 &&
      dotSymbol != -1 && dotSymbol != 0 &&
      (dotSymbol > atSymbol + 1) &&
      (email.length > dotSymbol + 1) &&
      spaceSymbol == -1
    )
  }

  handleErrorBorder1() {
    if (this.state.email === "null" || this.state.isEmailValid) {
      return "1px solid #CBD5E1";
    } else {
      return "1px solid #F87171";
    }
  }

  handleErrorBorder2() {
    if (this.state.showNameExistError) {
      return "1px solid #DC2626";
    } else {
      return "1px solid #CBD5E1";
    }
  }


  emailHelperText() {
    if (this.state.email === "null" || this.state.isEmailValid) {
      return "";
    } else {
      return "Invalid Email";
    }
  }

  navigateToLogin() {
    this.props.navigation.navigate("LoginPage");
  }

  openMenuItem() {
    this.setState({
      openMenuDropdown: !this.state.openMenuDropdown
    })
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
