// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch.web";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import LoginPage from "../../blocks/email-account-login/src/LoginPage.web";
import ForgotPasswordWeb from "../../blocks/email-account-login/src/ForgotPassword.web";
import ResetPassword from "../../blocks/email-account-login/src/ResetPassword.web";
import PreferencesPage from "../../blocks/email-account-login/src/PreferencesPage.web";
import TeenPreference from "../../blocks/user-profile-basic/src/TeenPreference.web";
import AdminDashboard from "../../blocks/user-profile-basic/src/AdminDashboard.web";



const routeMap = {
  Adminconsole2:{
   component:Adminconsole2,
  path:"/Adminconsole2"},
  LandingPage:{
   component:LandingPage,
  path:"/LandingPage"},
  OTPInputAuth:{
   component:OTPInputAuth,
  path:"/OTPInputAuth"},
  CountryCodeSelector:{
   component:CountryCodeSelector,
  path:"/CountryCodeSelector"},
  EmailAccountRegistration:{
   component:EmailAccountRegistration,
  path:"/SignUp"},
  AdvancedSearch:{
    component:AdvancedSearch,
   path:"/homescreen"},
  EmailAccountLoginBlock:{
   component:EmailAccountLoginBlock,
  path:"/EmailAccountLoginBlock"},
  LoginPage:{
    component: LoginPage,
   path:"/login"},
   ForgotPasswordWeb:{
    component: ForgotPasswordWeb,
   path:"/forgot"},
   ResetPassword:{
    component: ResetPassword,
   path:"/reset"},
   PreferencesPage:{
    component: PreferencesPage,
   path:"/preference"},
  SocialMediaAccountLogin:{
   component:SocialMediaAccountLogin,
  path:"/SocialMediaAccountLogin"},
  SocialMediaAccountRegistration:{
   component:SocialMediaAccountRegistration,
  path:"/SocialMediaAccountRegistration"},
  EducationalUserProfile:{
   component:EducationalUserProfile,
  path:"/EducationalUserProfile"},
  Uploadmedia3:{
   component:Uploadmedia3,
  path:"/Uploadmedia3"},
  Splashscreen:{
   component:Splashscreen,
  path:"/Splashscreen"},
  UserProfileBasicBlock:{
   component:UserProfileBasicBlock,
  path:"/UserProfile"},
  TeenPreference:{
    component:TeenPreference,
   path:"/TeenPreference"},
   AdminDashboard:{
    component:AdminDashboard,
   path:"/Dashboard"},
  Rolesandpermissions2:{
   component:Rolesandpermissions2,
  path:"/Rolesandpermissions"},
  ForgotPassword:{
   component:ForgotPassword,
  path:"/ForgotPassword"},
  ForgotPasswordOTP:{
   component:ForgotPasswordOTP,
  path:"/ForgotPasswordOTP"},
  NewPassword:{
   component:NewPassword,
  path:"/NewPassword"},
  TermsConditions:{
   component:TermsConditions,
  path:"/TermsAndPrivacy"},
  TermsConditionsDetail:{
   component:TermsConditionsDetail,
  path:"/TermsConditionsDetail"},
  TermsConditionsUsers:{
   component:TermsConditionsUsers,
  path:"/TermsConditionsUsers"},
  
    Home: {
      component: LandingPage,
      path: '/',
      exact: true,
    },
    InfoPage: {
      component: InfoPage,
      path: '/InfoPage',
    },
  
    Alert: {
      component: AlertBlock,
      path: "*/Alert",
      modal: true
    }
  
  };

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw', overflowX:"hidden" }}>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;