import React from "react";

// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { bottomImage, gradiant, image5, image6, image7, Logo, landingImg, userIcon, papers1, papers2, papers3, papers4, papers5, papers7, papers6 } from "./assets"


const StyledMainDiv = styled(Box)({
    overflowX:"hidden",
    justifyContent: "space-around",
    "@media (max-width: 457px)": {
        backgroundColor: "#F1F5F9",
        justifyContent: "center",
    },
})
const StyledBox = styled(Box)({
    "@media (max-width: 678px)": {
        display:"none"
    },
})

const StyledWrapper = styled(Box)({
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    "@media (max-width: 678px)": {
        width:"100%",
        height:"500px",
        justifyContent:"center"
    },
})
const StyledContent = styled(Box)({
    "@media (max-width: 678px)": {
        flexDirection: "column"
    },
})
const StyledmiddleWrap = styled(Box)({
     width: "100%",
    "@media (max-width: 678px)": {
    },
})
const StyledDiv = styled(Box)({
    "@media (max-width: 678px)": {
        justifyContent:"center"
    },
})

const StyledImg = styled(Box)({
    display:"none",
    "@media (max-width: 678px)": {
        display:"block",
    },
})

const StyledFooter = styled(Box)({
        width: "89.6%",
         backgroundColor: "#5E70DD",
        height: "264px",
        display: "flex",
        alignItems: "center",
        paddingRight: "80px",
        paddingLeft: "80px",
        justifyContent: "space-between",
      "@media (max-width: 678px)": {
        width: "61.6% !important",
        height: "500px !important",
        justifyContent:"center !important",
        flexDirection:"column",
        display: "none !important"
    },
})
const ContactWrapper = styled(Box)({
  
  "@media (max-width: 678px)": {
    alignItems:"center",
    marginTop: "20px",
    flexDirection:"column"
},
})

const BoxWrapper = styled(Box)({
     width: "74%",
     height: "60%",
    "@media (max-width: 678px)": {
        width: "90%",
        height: "90%",
        display: "flex",
       alignItems:"center",
      flexDirection:"column"
  },
  "& .headingText": {
    fontFamily: "Caudex",
    fontSize: "48px",
    fontWeight: 700,
    width: "92%",
    lineHeight: "52.8px",
    color: "#21272A",
    "@media (max-width: 768px)": {
     display: "none"
    },
  },
  "& .headingContent": {
      fontFamily: "Manrope",
      fontSize: "30px",
      fontWeight: 500,
      lineHeight: "42px",
      width: "240px",
      "@media (max-width: 768px)": {
          display: "none"
      },
  }
  })

  const TypoWrapper = styled("span")({
    display:"none",
   "@media (max-width: 678px)": {
    display:"block",
    fontFamily: "Caudex",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "26.4px"
 }
 })

 const ContentWrapper = styled("span")({
    display: "none",
   "@media (max-width: 678px)": {
    fontWeight: 500,
    display: "block",
    fontFamily:"Manrope",
    fontSize: "12px",
    lineHeight: "16.8px"
 }
 })

 const ImageWrapper = styled("img")({
    display:"none",
   "@media (max-width: 678px)": {
    display:"block"
 }
 })

 const Container = styled(Box)({
    display: "flex",
    height: "74px",
    alignItems: "center",
    padding: "0px 124px 0px 84px",
    justifyContent: "space-between",
   "@media (max-width: 678px)": {
    padding:"0px",
    justifyContent: "space-around",
 },
 "& .middleText" : {
    fontFamily: "Caudex",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "25.2px",
    color: "#21272A",
    "@media (max-width: 1350px)": {
     display: "none"
     
 }
}
 })

 const ContainerBox = styled(Box)({
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     maxWidth: "237px",
     width: "100%",
     height: "78px",
     gap: "10px",
   "@media (max-width: 678px)": {
    display:"none"
 }
 })

 const StyledWrap = styled(Box)({
     width: "100%",
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     padding: "52px",
     paddingBottom: "22px",
    "@media (max-width: 678px)": {
         gap: "0px",
         padding:"0px",
        justifyContent: "center",
        flexDirection: "column-reverse",
        alignItems: "center"
    },
})

const TermsWrap = styled(Box)({
    display: "flex",
    marginTop: "113px",
    gap: "10px",
    justifyContent: "flex-end",
    paddingRight: "8.2%", 
    "@media (max-width: 678px)": {
        marginTop: "20px",
        justifyContent: "center"
    }

})

const PaperWrap = styled(Box)({
    display: "flex",
    gap: "25.73px",
    flexWrap: "wrap",
    paddingTop: "32px",
    "@media (max-width: 768px)": {
      paddingTop: "0px",
      justifyContent: "center"
    },
  })
  const PaperColor = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "337.87px",
    width: "100%",
    height: "217.27px",
    backgroundColor: "#F1F5F9",
    borderRadius: "10px",
    "@media (max-width: 768px)": {
      backgroundColor: "#FFFFFF",
    },
  })

  const Wrapper = styled(Box)({
   width: "64%",
    "@media (max-width: 768px)": {
    width: "100%",
    },
  })

  const TypoWrap = styled("span")({
      fontFamily: "Caudex",
      fontSize: "48px",
      fontWeight: 700,
      lineHeight: "52.8px",
      color: "#21272A", 
     "@media (max-width: 768px)": {
        textAlign: "center",
        display: "flex"
     },
   })

   const BottomWrapperss = styled(Box)({
    width: "100%",
    display:"none",
    backgroundColor: "#5E70DD",
    marginTop: "40px",
    paddingBottom: "1px",
    paddingTop:"1px",
    "@media (max-width: 768px)": {
      display: "block"
    },
  })


  



// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";
export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End 
    // Customizable Area Start
    render() {
        let paperContent = [
            {"imgs": papers1, "title": "Tech Help", "content": "Your teen assistant can help you troubleshoot and fix minor issues with apps, logins, or wifi for your phone, laptop, or other devices."},
            {"imgs": papers2, "title": "Indoor Help", "content": "Your teen assistant can help you change lightbulbs, carry in boxes or groceries, water and maintain houseplants, help with meal prep, get laundry and housekeeping done, and more."},
            {"imgs": papers3, "title": "Outdoor Help", "content": "Your teen assistant can mow and edge your lawn, plant seeds or starters, spread mulch, blow or rake debris, and more."},
            {"imgs": papers4, "title": "Friendly Visits", "content": "Your teen assistant will stop by at a scheduled time to visit, play games or help with hobbies, and ask if you need help with anything. We'll send a brief report of the visit to you and your trusted circle."},
            {"imgs": papers5, "title": "Organizing", "content": "Your teen assistant can help you sort through, organize, box up, label, and arrange items in your cabinets, closets, garage, or shed. If you have items to sell online or donate, your teen assistant can help with this too."},
            {"imgs": papers6, "title": "Pet Care", "content": "Your teen assistant can walk your dog, change the litter box, fill pet food bins, brush or play with your furry family member, clean out the fish tank, and more."},
            {"imgs": papers7, "title": "Run Errands", "content": "Your teen assistant will use their own transportation to pick up groceries or small household items, pick up mail or packages, drop off items for shipping, take items to charity donation sites, and more."},
          ]
        return (
            <StyledMainDiv>
                <Container>
                    <Box style={{display:"flex", gap:"80px"}}>
                    <img src={Logo.default} style={{ width: "195px", height: "21px" }} />
                    <ImageWrapper src={userIcon.default} />
                    </Box>
                    <Typography className="middleText">Senior Year is an elite squad of teens helping seniors in their community.</Typography>
                    <ContainerBox>
                        <Typography style={{...webStyles.typo, paddingRight:"40px"}} onClick={this.handleNavigation.bind(this,"LoginPage")} >Login</Typography>
                        <Box style={{ color: "#D6DDEB", height: "48px", border: "1px solid #D6DDEB" }}></Box>
                        <Button style={webStyles.btn}  onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign Up</Button>
                    </ContainerBox>


                </Container>
                <StyledDiv style={{ height: "676px", width: "100%", gap: "40px", display: "flex",backgroundImage: `url(${gradiant.default})`, backgroundSize:"cover" }}>
                    <StyledWrapper >
                        <BoxWrapper>
                            <TypoWrapper>Find your job!</TypoWrapper>
                            <Typography className="headingText">We're on a mission   disguised as a high school job:</Typography>
                            <StyledImg>
                            <img src={landingImg.default}  style={{ width: "336.87px",height: "348.63px" }}/>
                            </StyledImg>
                            <Box style={{marginTop:"10px", marginBottom:"15px"}}>
                                <Typography className="headingContent">Help others.
                                        Ensure Safety.
                                        Build Skills.
                                        End loneliness.</Typography>
                            <ContentWrapper>When you hire a teen through Senior Year, you provide an essential and motivational opportunity for a young person to connect with their broader community and find value in growing their job skills.</ContentWrapper>
                            </Box>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <Button style={webStyles.boxBtn} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Hire a Teen</Button>
                                <Button style={{ ...webStyles.boxBtn, border: "1px solid #5E70DD", color: "#5E70DD", backgroundColor: "transparent" }}
                                onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}
                                >Get a Job</Button>
                            </Box>
                        </BoxWrapper>
                    </StyledWrapper>
                    <StyledBox style={{ width: "45%", height: "473px" }}>
                        <img src={landingImg.default} style={{width:"100%", paddingTop:"20px"}}/>
                    </StyledBox>
                </StyledDiv>
                <StyledmiddleWrap >
                    <StyledWrap>
                            <img src={bottomImage.default} style={{ maxWidth: "436.91px", width:"100%", height: "461.06px" }} />
                        <Wrapper>
                            <TypoWrap>What our teens do:</TypoWrap>
                            <PaperWrap>
                                {
                                paperContent.map( (item: any) =>
                                    <PaperColor>
                                <Box style={{paddingTop:"30px", paddingLeft:"30px",}}>
                                    <Box style={{display:"flex", gap:"10px",}}>
                                    <img src={item.imgs.default} style={{ width:"27.62px", height:"27.62px", }} />
                                    <Typography style={webStyles.contentTitle} >{item.title}</Typography>
                                    </Box>
                                    <Typography style={{...webStyles.contentTypo, marginTop: "20px", }}>{item.content} </Typography>
                                    </Box>
                                </PaperColor>
                                )
                                }
                                </PaperWrap>
                        </Wrapper>
                    </StyledWrap>
                <StyledFooter 
                style={{
                    width: "89.6%", backgroundColor: "#5E70DD", height: "264px", display: "flex", alignItems: "center",
                    paddingRight: "80px", paddingLeft: "80px", justifyContent: "space-between"
                }}
                >
                    <Box style={{ width:"100%", maxWidth: "457px", height: "203px" }}>
                        <img src={image5.default} style={{ width: "54px", height: "43px", marginBottom: "10px" }} />
                        <Typography style={webStyles.footerText}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                        <Typography style={webStyles.emailText}>CompanyName @ 202X. All rights reserved.</Typography>
                    </Box>

                    <Box style={{height:"203px",width:"600px", marginTop:"60px" }}>
                        <ContactWrapper style={{ display: "flex", gap: "10px" }}>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <img src={image6.default} style={{ width: "36px", height: "36px" }} />
                                <Box>
                                    <Typography style={webStyles.smallTypo}>Contact us at</Typography>
                                    <Typography style={webStyles.emailText}>senioryear@outlook.com</Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <img src={image7.default} style={{ width: "36px", height: "36px" }} />
                                <Box>
                                    <Typography style={webStyles.smallTypo}>Have a question?</Typography>
                                    <Typography style={webStyles.emailText}>310-437-2766</Typography>
                                </Box>
                            </Box>
                            <Button style={webStyles.signBtn} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign Up</Button>
                        </ContactWrapper>
                        <Box>
                            <TermsWrap>
                                <Typography style={webStyles.emailText} onClick={this.goTermsConditions} >Terms & Conditions</Typography>
                            </TermsWrap>
                        </Box>

                    </Box>



                </StyledFooter>
                <BottomWrapperss>
              <Box style={{maxWidth: "457px", width:"100%", height: "90px", marginTop:"30px" }}>
                <img src={image5.default} style={{paddingLeft:"20px", width: "32.14px", height: "25.59px"}} />
                <Box style={{display:"flex", padding:"0px 20px", alignItems:"center"}}>
                <Typography style={{
                  fontFamily: "Manrope", fontSize: "10.71px", fontWeight: 400, lineHeight: "15px",
                  color: "#FFFFFF", marginTop:"10px"
                }}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                <Button style={{...webStyles.signupBtn, width:"160px", borderRadius:"10px"}}
                onClick={() => this.handleNavigation("EmailAccountRegistration")}
                data-test-id = "signupId">Sign Up</Button>
                </Box>
              </Box>
                <Box>
              <Box style={{display:"flex", paddingLeft: "20px", gap: "20px", flexWrap: "wrap"}}>
                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={image7.default} style={{width: "23.43px", height: "23.43px"}} />
                  <Box>
                    <Typography style={{fontFamily: "Manrope", fontSize: "7.81px", fontWeight: 400, lineHeight: "10.67px", color:"#ECECEC"}}>Contact us at</Typography>
                    <Typography style={{
                      fontFamily: "Manrope",
                      fontSize: "10.41px",
                      fontWeight: "400",
                      lineHeight: "14.22px",
                      color:"#FFFFFF"
                      }}>senioryear@outlook.com</Typography>
                  </Box>
                </Box>

                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={image6.default} style={{width: "23.43px", height: "23.43px"}} />
                  <Box>
                    <Typography style={{fontFamily: "Manrope", fontSize: "7.81px", fontWeight: 400, lineHeight: "10.67px", color:"#ECECEC"}}>Have a question?</Typography>
                    <Typography style={{fontFamily: "Manrope",
                      fontSize: "10.41px",
                      fontWeight: "400",
                      color:"#FFFFFF",
                      lineHeight: "14.22px"}}>310-437-2766</Typography>
                  </Box>
                </Box>
              </Box>
              <Box style={{display:"flex", gap:"20px", marginTop:"26px",marginBottom:"26px", paddingLeft:"20px"}}>
                <Typography style={{fontFamily: "Roboto",
                fontSize: "9.21px",
                fontWeight: "400",
                lineHeight: "12.9px",
                color:"#FFFFFF"
                }}>CompanyName @ 202X. All rights reserved</Typography>

                <Typography style={{...webStyles.terms, fontSize:"12.1px", lineHeight:"12.1px"}} onClick={this.goTermsConditions}>Terms & Conditions</Typography>
              </Box>
              </Box>
            </BottomWrapperss>
                </StyledmiddleWrap>


            </StyledMainDiv>
        )
    }
}

export const webStyles = {
    typo: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#5E70DD"

    },
    iconWidth: {
        width: "40px",
        height: "36px",
        marginBottom: "16px"
    },
    imgText: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",

    },
    boxAlign: {
        maxWidth: "380px",
        width: "100%",
        height: "114px",
    },
    btn: {
        textTransform: "initial" as "initial",
        height: "50px",
        padding: "12px 24px 12px 24px",
        backgroundColor: "#5E70DD",
        borderRadius:"10px",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "25.6px",
        color: "#FFFFFF"
    },
    boxBtn: {
        width: "173px",
        textTransform: "initial" as "initial",
        backgroundColor: "#5E70DD" ,
        height: "56px",
        borderRadius: "12px",
        border: "2px 0px 0px 0px",
        color: "#FFFFFF"
    },
    footerText: {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",
        color: "#FFFFFF",
        marginBottom: "80px"
    },
    emailText: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "21.86px",
        color: "#FFFFFF"

    },
    smallTypo: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#ECECEC"

    },
    signBtn: {
        width: "125px",
        height: "34px",
        padding: "12px 24px 12px 24px",
        backgroundColor: "#000000",
        fontFamily: "Epilogue",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#FFFFFF",
        textTransform: "initial" as "initial"
    },
    contentTitle: {
        fontFamily: "Manrope",
        fontWeight: 700,
        fontSize: "22.1px",
        lineHeight: "30.18px",
        color: "#000000"
      },
      contentTypo: {
        fontFamily: "Manrope",
        fontSize: "14.73px",
        fontWeight: 400,
        lineHeight: "20.12px",
        width: "301px"
      },
      signupBtn: {
        width: "108px",
        height: "34px",
        textTransform: "capitalize" as "capitalize",
        color:"#FFFFFF",
        backgroundColor: "#000000",
        fontFamily: "Epilogue",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px"
      },
      terms: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#FFFFFF"
      },
}
// Customizable Area End
