import React from "react";
// Customizable Area Start
import ResetPasswordController, { Props } from "./ResetPasswordController";
import { Grid, Button, Typography, Box, IconButton, TextField, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff, RemoveCircle, CheckCircle  } from '@mui/icons-material';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { styled } from "@mui/styles";
import { Gradient, LoginImage, headerLogo, imageLogo } from "./assets";


const StyledBox = styled(Box)({
    width:"50%",
    position:"relative",
    "@media (max-width: 768px)": {
        display:"none"
      },
})
const BoxWrapper = styled(Box)({
    width:"50%",
    "@media (max-width: 768px)": {
        width:"100%",
      },
})

const BoxWrap = styled("img")({
  display:"none",
 "@media (max-width: 768px)": {
  width: "157.04px",
  height: "16.91px",
  display: "block"
   },
})

// Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNewPassword = () => {
    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Minimum 8 characters')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number'),
        // .matches(8, 'Minimum 8 characters'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    });

return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      validationSchema={validationSchema}
      data-test-id = "changePasswordId"
      onSubmit={(values) => {
        this.changePasswordApi(values)
      }}
    >
      {({ values, errors, handleSubmit,touched }) => (
        <form className="thirdForm" onSubmit={handleSubmit}>
          <Box className="firstNameField">
            <Box>
              <Typography variant="subtitle1" className="firstNamePlaceHolder">
                New password
              </Typography>
              <Field
                as={TextField}
                name="password"
                data-test-id = "fieldId1"
                type={this.state.showPassword ? "text" : "password"}
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Your password"
                className={this.state.passwordError ? "errorOutline" : ''}
                onFocus={() => this.setState({ showPasswordCriteria: true })}
                onBlur={() => this.setState({ showPasswordCriteria: false })}
                inputProps={{ "data-test-id": "newPassword" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        data-test-id="toggleNewPasswordVisibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {this.state.passwordError && <Typography className="errormsg errorOutline">{this.state.passwordError}</Typography>}
            </Box>
            <Box>
              <Typography variant="subtitle1" className="firstNamePlaceHolder">
                Confirm new password
              </Typography>
              <Field
                as={TextField}
                name="confirmPassword"
                type={this.state.showConfirmPassword ? "text" : "password"}
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Confirm your new password"
                inputProps={{ "data-test-id": "confirmPassword" }}
                className={errors.confirmPassword && touched.confirmPassword ? "errorOutline" : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-test-id="toggleConfirmPasswordVisibility'"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowConfirmPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <ErrorMessage name="confirmPassword" className="errormsg" component="div"
               />
            </Box>
            {
            (
              <Box mt={2}>
                <Typography style={webStyles.errorParent}>Your password must contain:</Typography>
                <Box style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                  <Box className="passwordErrorBox" display="flex" alignItems="center">
                    <IconButton>
                      {/[A-Z]/.test(values.password) ? (
                        <CheckCircle style={{ width:"12px", height:"12px", color: '#5E70DD' }} />
                      ) : (
                        <RemoveCircle style={{ width:"12px", height:"12px", color: 'red' }} />
                      )}
                    </IconButton>
                    <Typography className="passError">At least one uppercase letter</Typography>
                  </Box>
                  <Box className="passwordErrorBox errorBox" display="flex" alignItems="center">
                    <IconButton>
                      {/[a-z]/.test(values.password) ? (
                        <CheckCircle style={{width:"12px", height:"12px", color: '#5E70DD' }} />
                      ) : (
                        <RemoveCircle style={{width:"12px", height:"12px", color: 'red' }} />
                      )}
                    </IconButton>
                    <Typography className="passError">At least one lowercase letter</Typography>
                  </Box>
                  <Box className="passwordErrorBox errorBox" display="flex" alignItems="center">
                    <IconButton>
                      {/\d/.test(values.password) ? (
                        <CheckCircle style={{width:"12px", height:"12px", color: '#5E70DD' }} />
                      ) : (
                        <RemoveCircle style={{width:"12px", height:"12px", color: 'red' }} />
                      )}
                    </IconButton>
                    <Typography className="passError">At least one number</Typography>
                  </Box>
                  <Box className="passwordErrorBox errorBox" display="flex" alignItems="center">
                    <IconButton>
                      {(values.password.length >= 8) ? (
                        <CheckCircle style={{width:"12px", height:"12px", color: '#5E70DD' }} />
                      ) : (
                        <RemoveCircle style={{width:"12px", height:"12px", color: 'red' }} />
                      )}
                    </IconButton>
                    <Typography className="passError">Minimum 8 characters</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Button data-test-id="setNew" type="submit" className='continueBtn' variant="contained"
           style={{backgroundColor:"#5E70DD",fontFamily: "Manrope", fontSize: "16px",fontWeight: 700,lineHeight: "24px",
                 color:"white"}}>
            Set New Password
          </Button>
        </form>
      )}
    </Formik>
);
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <Box style={{display:"flex", overflowX: "hidden", backgroundImage: `url(${Gradient.default})`}} data-test-id ="ResetPage">
            <BoxWrapper>
      <MainGrid xs={12} container data-test-id="NewPassword">
        <ToastContainer />
        <Grid item xs={12} className="logoGrid">
        </Grid>
        <Grid container item xs={12} justifyContent="center" alignItems="center" className="secondGridItem">
        <Box style={{display: "flex", justifyContent: "center", paddingTop: "20px"}}>
              <BoxWrap src={headerLogo.default} style={{paddingTop: "50px"}}/>
          </Box>
          <Box
            p={3}
            className="formBox"
          >
            <Typography variant="h4" gutterBottom className="signupText">
              New Password
            </Typography>
            <Typography className='newPasswordText'>
              Enter a new password for example@email.com
            </Typography>
            <Box mt={2} mb={2}>
              {this.renderNewPassword()}
            </Box>
            <Box className="accountCreate">
              <Typography className="accountQuestion">Back to <span  style={webStyles.spanText} data-test-id="loginNavigation"
               onClick={()=> this.handleNavigation("LoginPage")}> Log in</span></Typography>
            </Box>
          </Box>
        </Grid>
      </MainGrid>
            </BoxWrapper>
        <StyledBox>
            <img src={LoginImage.default} style={{width:"100%"}} />
            <img src={imageLogo.default} style={{ width:"152px", height: "114px", position: "absolute", top: "46px", right: "38%"}}/>
           </StyledBox>
        </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainGrid = styled(Grid)({
  height: '100vh',
  position: 'relative',
  "& .logoGrid": {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: "13px",
    color: "#ffff",
    height: '10%',
    "@media (max-width: 768px)": {
      display:"none"
    },
  },
  "& .logo": {
    width: '100px',
    height: 'auto',
  },
  "& .secondGridItem": {
    height: '78%',
    marginTop: '-5%',
  },
  "& .formBox": {
    width: '100%',
    maxWidth: '400px',
  },
  "& .signupText": {
    width: '100%',
    marginBottom: "30px",
    fontFamily: "Manrope",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    textAlign: "left",
    letterSpacing: "-0.11999999731779099px",
    "@media (max-width: 768px)": {
      marginBottom: "0px",
    },

  },
  "& .continueBtn": {
    width: "100%",
    padding: "20px",
    marginTop: "30px",
    borderRadius: "10px",
    backgroundColor: "#355EE7",
    color: "white",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Manrope",
    lineHeight: "21px",
    '&:disabled': {
      backgroundColor: "#D9D9D9",
    },
  },
  "& .firstNamePlaceHolder": {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left"
  },
  "& .newPasswordText": {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "30px 0",
    textAlign: "left",
    "@media (max-width: 768px)": {
      margin: "0px 0",
    },
  },
  "& .passwordErrorBox": {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important"
  },
  "& .accountCreate": {
    display: "flex",
    marginTop: "40px",
    "@media (max-width: 768px)": {
      justifyContent: "center",
    },
  },
  "& .accountQuestion": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    fontFamily: "Manrope"
  },
  "& .errormsg": {
    color: '#DC2626',
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "10px",
  },
  "& .errorOutline": {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  },
  "& .passError": {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px"
  },
});
export const webStyles= {
  errorParent: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",

  },
    spanText: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left" as "left",
        color:"#5E70DD" 
    },
    errorMsgText: {
         color: '#DC2626',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 600
    }
}
// Customizable Area End

