import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  currentTab: number
  // Customizable Area Start
  isOpen: boolean;
  renderStatus: string
  deleteModal: boolean
  oldPassword: string,
  newPassword: string,
  errorStatus: boolean;
  errorMsg: string;
  error: boolean;
  userRole: any
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordCallId: string = "";
  deleteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
        getName(MessageEnum.CountryCodeMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.ReciveUserCredentials),
        getName(MessageEnum.NavigationMessage),
      
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      currentTab: 1
      // Customizable Area Start
      ,isOpen: false,
      oldPassword: "",
      newPassword: "",
      deleteModal: false,
      renderStatus: "",
      errorStatus: false,
      errorMsg: "",
      error: false,
      userRole: JSON.parse(String(localStorage.getItem("login")))
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.deleteCallId) {
      this.handleResposes(responseJson) 
      }
      if (apiRequestCallId === this.resetPasswordCallId) {
            this.handleResponse(responseJson)
        }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleNavigation = (route: string) => {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  logout = () => {
    localStorage.clear();
    this.handleNavigation("LandingPage")
  }
  openModal = () => {
    this.setState({isOpen: true })
  }

  openDeleteModal = (status: string) => {
    this.setState({deleteModal: true, renderStatus: status })
  }

  closeModal = () => {
    this.setState({isOpen: false, deleteModal: false, oldPassword: "", newPassword: "", renderStatus:"" })
  }
  goTermsConditions = () => {
    localStorage.setItem("termsAndPolicy","2")
    this.handleNavigation("TermsConditions")
  }

  handleText = (event: any) => {
    let {name, value} = event.target
    if(name == "oldPass"){
      this.setState({oldPassword: value})
    }else{
      this.setState({newPassword: value})
    }
  }
  resetPasswordApi = () => {
    let data = JSON.parse(String(localStorage.getItem("login")))

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": data?.token
    };
    let body = {
      "data": {
          "current_password": this.state.oldPassword,
          "new_password": this.state.newPassword
      }
  }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleResposes = (data: any) => { 
    if(data?.errors) {
      this.setState({errorMsg: data?.errors, errorStatus: true, oldPassword:"", error: true })
    }else{
      this.logout()
    }
  }
  
  deleteAccount = () => {
    let data = JSON.parse(String(localStorage.getItem("login")))
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": data?.token
    };
    let body = {
      "password": this.state.oldPassword
  }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }
  handleResponse = (responseJson: any) => {
    if(responseJson?.errors)
      {
        this.setState({errorMsg:responseJson.errors, errorStatus: true, oldPassword:"", newPassword:"", error: true})
      }else{
         this.closeModal()
      }

  }
  // Customizable Area End
}
