export const bottomImage = require("../assets/bottomImage.svg");
export const topImage = require("../assets/topImage.png");
export const Logo = require("../assets/Logo.svg");
export const image5 = require("../assets/image5.svg");
export const image6 = require("../assets/image6.png");
export const image7 = require("../assets/image7.png");
export const gradiant = require("../assets/gradiant.png");
export const landingImg = require("../assets/Frame.png");
export const userIcon = require("../assets/userIcon.svg");
export const papers1 = require("../assets/paper1.svg")
export const papers2 = require("../assets/paper2.svg")
export const papers3 = require("../assets/paper3.svg")
export const papers4 = require("../assets/paper4.svg")
export const papers5 = require("../assets/paper5.svg")
export const papers6 = require("../assets/paper6.svg")
export const papers7 = require("../assets/paper7.svg")
