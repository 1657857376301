import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    progress: number;
    currentTab: number;
    fieldValue: string;
     options: any[];
     radioValue: boolean
     secondRadioValue: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TeenPreferenceController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    APiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            progress: 0,
            currentTab: 0,
            fieldValue: "",
             options: [
                { id: 1, label: 'Elgin St. Celina' },
                { id: 2, label: '6391 Elgin St. Celina, Delaware 10299' },
              ],
              radioValue: false,
              secondRadioValue: false
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));


        // Customizable Area End
    }
    // Customizable Area Start

  handlePrevious = () => {
    this.setState({progress: this.state.progress - 14.28, currentTab: this.state.currentTab-1})
  }

  handleNext = () => {
        this.setState({progress: this.state.progress + 14.28, currentTab: this.state.currentTab+1})
  }
  handleChange = (event: any) => {
    this.setState({fieldValue: event.target.value})
  }
  handleRadio = (event: any) => {
    let { name } = event.target
    if(name === "radio1"){
        this.setState({radioValue: true, secondRadioValue: false})
    }else{
        this.setState({secondRadioValue: true , radioValue: false}) 
    }
  }



    // Customizable Area End

}

